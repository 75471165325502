import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Input,
  LinearProgress,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HttpService from "../services/http.service";
import { getSamplesCreated } from "../services/drxna.service";
import { drxnaUrl } from "../services/url.service";
import PackageInspection from "../components/PackageInspection";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import AccordionCustom from "../components/AccordionCustom";

const ShipmentDetail = () => {
  const navigate = useNavigate();
  const [shipmentDetail, setShipmentDetail] = useState({});
  const [fetchAvailableSamples, setFetchAvailableSamples] = useState(false);
  const [showAddMoreBtn, setShowAddMoreBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateTrackingId, setUpdateTrackingId] = useState(false);
  const [samplesInfo, setSamplesInfo] = useState([]);
  const [packageInfo, setpackageInfo] = useState({});
  const { shipmentId } = useParams();

  const showToast = (message, success = true, duration = 2000) => {
    Toastify({
      text: message,
      duration: duration,
      close: true,
      gravity: "top",
      position: "right",
      backgroundColor: success ? "#22c55e" : "#ef4444",
      color: "#fff",
      style: {
        fontFamily: '"Open Sans", sans-serif',
      },
    }).showToast();
  };

  const getOutboundData = () => {
    HttpService.get(`${drxnaUrl}shipment/${shipmentId}/inspection/outbound`)
      .then((res) => {
        const selecteds = res?.data?.data?.sample_list || [];
        setSelectedSampleIds(selecteds);
        if (selecteds?.length === 0) {
          setFetchAvailableSamples(true);
          getAllSamples(currentPage);
        } else {
          setShowAddMoreBtn(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    HttpService.get(`${drxnaUrl}shipment/${shipmentId}`)
      .then((res) => {
        setShipmentDetail(res?.data?.data);
        setTrackingId(res?.data?.data?.tracking_id || "");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    getOutboundData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedSampleIds, setSelectedSampleIds] = useState([]);

  const [selecteds, setSelecteds] = useState([]);

  const [isAddingSample, setIsAddingSample] = useState({
    status: false,
    id: "",
  });
  const AddSampleToShipment = (sample) => {
    setLoading(true);
    setIsAddingSample({ status: true, id: sample?.["Sample Id"] });
    HttpService.put(
      `${drxnaUrl}shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: [sample?.["Sample Id"]] },
    )
      .then((res) => {
        if (res?.data?.data) {
          setSelectedSampleIds((prev) => [
            ...prev,
            {
              sample_id: sample?.["Sample Id"],
              sample_name: sample?.["Sample Name"] || null,
              inspection_status: sample?.inspection_status || null,
            },
          ]);
          getAllSamples(currentPage);
          showToast("Sample Added to shipment", true);
        } else {
          showToast("Sample is already in another package", false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setIsAddingSample({ status: false, id: sample?.["Sample Id"] });
      });
  };

  const AddSamplesToShipment = () => {
    const sampleIds = selecteds?.map((item) => item?.sample_id);
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: sampleIds },
    )
      .then((res) => {
        if (res?.data?.data) {
          getAllSamples(currentPage);
          setSelectedSampleIds((prev) => [...prev, ...selecteds]);
          setSelecteds([]);
          showToast("Samples Added to shipment", true);
        } else {
          showToast("Samples are already in another package", false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeSampleToShipment = (id) => {
    setLoading(true);
    HttpService.remove(`${drxnaUrl}shipment/${shipmentId}/sample/${id}`)
      .then((res) => {
        if (!res?.data?.error) {
          const filtered = selectedSampleIds.filter(
            (sample) => sample.sample_id !== id,
          );
          setSelectedSampleIds(filtered);
          setSamplesInfo((prev) => {
            return prev.filter((sample) => sample.sampleId !== id);
          });
          if (fetchAvailableSamples) {
            getAllSamples(currentPage);
          } else if (filtered?.length === 0) {
            setFetchAvailableSamples(true);
            getAllSamples(currentPage);
          }
          showToast("Sample removed from package successfully!", true);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Failed to remove sample from package", false);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const confirmShipment = () => {
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}shipment/${shipmentId}/inspection/outbound/confirm?final_status=OK`,
    )
      .then((res) => {
        if (res?.data?.data?.inspection_status) {
          setShowConfirm(true);
          showToast("Shipment confirmed!", true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [trackingId, setTrackingId] = useState("");

  const addTrackingId = () => {
    setLoading(true);
    HttpService.put(`${drxnaUrl}shipment/${shipmentId}`, {
      tracking_id: trackingId,
    })
      .then((res) => {
        setShipmentDetail((prev) => {
          return {
            ...prev,
            tracking_id: res?.data?.data?.tracking_id,
            shipment_status: res?.data?.data?.shipment_status,
          };
        });
        setUpdateTrackingId(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [samplesList, setSamplesList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getAllSamples = (p) => {
    setLoading(true);
    getSamplesCreated(p, 10)
      .then((res) => {
        setSamplesList(res?.data?.data?.samples || []);
        setPage(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fetchAvailableSamples) {
      getAllSamples(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleCheckboxChange = (event, sample) => {
    if (event.target.checked) {
      setSelecteds((prev) => [
        ...prev,
        {
          sample_id: sample?.["Sample Id"],
          sample_name: sample?.["Sample Name"] || null,
          inspection_status: sample?.inspection_status || null,
        },
      ]);
    } else {
      setSelecteds((prev) =>
        prev?.filter((item) => item?.sample_id !== sample?.["Sample Id"]),
      );
    }
  };

  const [checked, setChecked] = useState(false);

  const handleToggleChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleValidate = () => {
    const missingFields = samplesInfo
      .map((sample) => {
        const missing = [];
        if (sample?.image_list?.length === 0) missing.push("Image");
        if (sample?.description === "") missing.push("description");
        if (sample?.file_list?.length === 0) missing.push("File");
        if (sample?.video_list?.length === 0) missing.push("Video");
        return {
          sampleId: sample?.sampleId,
          missingFields: missing,
        };
      })
      .filter((sample) => sample?.missingFields?.length > 0);

    const missingFieldsPackage = [];
    if (packageInfo?.image_list?.length === 0)
      missingFieldsPackage.push("Image");
    if (packageInfo?.description === "")
      missingFieldsPackage.push("description");
    if (packageInfo?.file_list?.length === 0) missingFieldsPackage.push("File");
    if (packageInfo?.video_list?.length === 0)
      missingFieldsPackage.push("Video");

    if (selectedSampleIds?.length === 0) {
      showToast("There are no samples in this shipment", false, 3000);
    } else if (
      missingFields?.length === 0 &&
      missingFieldsPackage?.length === 0
    ) {
      confirmShipment();
    } else {
      if (missingFields?.length > 0) {
        missingFields?.forEach((sample, index) => {
          const message = `Sample: ${sample.sampleId} is missing: ${sample.missingFields.join(", ")}`;
          setTimeout(() => {
            showToast(message, false, 3000);
          }, index * 100);
        });
      }
      if (missingFieldsPackage?.length > 0) {
        const packageErr = `package is missing: ${missingFieldsPackage.join(", ")}`;
        showToast(packageErr, false, 3000);
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box sx={{ p: { xs: 1, sm: 2, md: 5 } }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            mt: { xs: 2, md: 0 },
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              sx={{ mr: 1, cursor: "pointer" }}
              onClick={() => {
                navigate({ pathname: "/shipments" });
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "22px", lg: "28px" },
                fontWeight: "600",
                color: "#1e293b",
                fontFamily: "Open Sans",
              }}
            >
              Package Detail
            </Typography>
          </Box>

          {showConfirm || shipmentDetail?.outbound_data?.inspection_status ? (
            <Box>
              {" "}
              {(shipmentDetail?.shipment_status === "delivered" ||
                shipmentDetail?.shipment_status === "enroute") && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/track/${shipmentDetail?._id}`);
                  }}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "#1e5267",
                    ":hover": { background: "#1e5267" },
                    borderRadius: "8px",
                    fontFamily: "Open Sans",
                    height: "45px",
                    px: 3,
                  }}
                >
                  Track Shipment
                </Button>
              )}
            </Box>
          ) : (
            <Button
              onClick={() => {
                handleValidate();
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
                borderRadius: "8px",
                fontFamily: "Open Sans",
                height: "45px",
                px: 3,
              }}
            >
              Confirm Shipment
            </Button>
          )}
        </Box>
        <Grid
          container
          sx={{
            border: "1px solid #cbd5e1",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                backgroundColor: "#fff",
                p: 2,
              }}
            >
              <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                {" "}
                Name:
              </Typography>
              <Box
                sx={{ width: "100%", overflowX: "auto", wordBreak: "keep-all" }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {shipmentDetail?.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                backgroundColor: "#fff",
                p: 2,
              }}
            >
              <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                Id:
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Open Sans",
                  fontWeight: "600",
                }}
              >
                {shipmentDetail?._id}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                backgroundColor: "#fff",
                p: 2,
              }}
            >
              <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                Shipment Status:
              </Typography>
              <Typography
                sx={{
                  color:
                    shipmentDetail?.shipment_status === "cancelled"
                      ? "red"
                      : shipmentDetail?.shipment_status === "delivered"
                        ? "#22c55e"
                        : shipmentDetail?.shipment_status === "enroute"
                          ? "#0ea5e9"
                          : "#000",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                }}
              >
                {shipmentDetail?.shipment_status}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                backgroundColor: "#fff",
                p: 2,
              }}
            >
              {shipmentDetail?.shipment_status !== "delivered" &&
                shipmentDetail?.shipment_status !== "cancelled" &&
                (showConfirm ||
                  shipmentDetail?.outbound_data?.inspection_status) && (
                  <Box>
                    <Typography
                      sx={{ fontSize: "14px", fontFamily: "Open Sans" }}
                    >
                      Tracking Id:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "end",
                        mt: { xs: "20px", sm: 0 },
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: { sm: "350px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          border: shipmentDetail?.tracking_id
                            ? updateTrackingId
                              ? "1px solid #22c55e"
                              : "1px solid #eab308"
                            : "1px solid #1e5267",
                          borderRadius: "8px",
                          width: "100%",
                          maxWidth: { sm: "350px" },
                        }}
                      >
                        <Input
                          value={trackingId}
                          disabled={
                            shipmentDetail?.tracking_id
                              ? updateTrackingId
                                ? false
                                : true
                              : false
                          }
                          disableUnderline
                          onChange={(e) => {
                            setTrackingId(e.target.value);
                          }}
                          variant="contained"
                          placeholder="Tracking Id"
                          sx={{ px: 1, width: "100%", height: "100%" }}
                        />
                        <Button
                          onClick={() => {
                            if (!shipmentDetail?.tracking_id) {
                              if (trackingId.trim() !== "") {
                                addTrackingId();
                              } else {
                                showToast(
                                  "Tracking Id can not be empty",
                                  false,
                                );
                              }
                            } else {
                              if (updateTrackingId) {
                                if (trackingId.trim() !== "") {
                                  addTrackingId();
                                } else {
                                  showToast(
                                    "Tracking Id can not be empty",
                                    false,
                                  );
                                }
                              } else {
                                setUpdateTrackingId(true);
                              }
                            }
                          }}
                          variant="contained"
                          size="sm"
                          sx={{
                            textTransform: "none",
                            background: shipmentDetail?.tracking_id
                              ? updateTrackingId
                                ? "#22c55e"
                                : "#eab308"
                              : "#1e5267",
                            ":hover": {
                              background: shipmentDetail?.tracking_id
                                ? updateTrackingId
                                  ? "#22c55e"
                                  : "#eab308"
                                : "#1e5267",
                            },
                            borderRadius: "0 7px 7px 0",
                            fontFamily: "Open Sans",
                            width: "100px",
                          }}
                        >
                          {shipmentDetail?.tracking_id
                            ? updateTrackingId
                              ? "Update"
                              : "Edit"
                            : "Add"}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>

        <PackageInspection
          setpackageInfo={(val) => {
            setpackageInfo(val);
          }}
          id={shipmentId}
          setLoading={(val) => {
            setLoading(val);
          }}
          showToast={(message, success) => {
            showToast(message, success);
          }}
          status={shipmentDetail?.shipment_status}
        />
        <Box
          sx={{
            width: "100%",
            mt: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "22px", lg: "28px" },
              fontWeight: "600",
              color: "#1e293b",
              fontFamily: "Open Sans",
              mb: 2,
            }}
          >
            Samples in Package
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                mt: 2,
                background: "#fff",
              }}
            >
              {selectedSampleIds?.length !== 0 ? (
                selectedSampleIds?.map((sample, index) => (
                  <Box
                    variant="outlined"
                    sx={{
                      fontFamily: "Open Sans",
                    }}
                    key={index}
                  >
                    <Box>
                      <AccordionCustom
                        key={index}
                        setSamplesInfo={(val) => {
                          setSamplesInfo(val);
                        }}
                        sample={sample}
                        shipmentDetail={shipmentDetail}
                        removeSampleToShipment={removeSampleToShipment}
                        loading={loading}
                        setLoading={setLoading}
                        showToast={showToast}
                        status={shipmentDetail?.shipment_status}
                      />
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    border: "1px solid #e2e8f0",
                    height: "150px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "26px",
                      color: "#334155",
                    }}
                  >
                    No samples in package.
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      color: "#334155",
                      fontSize: "12px",
                    }}
                  >
                    Please choose samples below to include in your package.
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              {showAddMoreBtn &&
                shipmentDetail?.shipment_status === "created" && (
                  <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={() => {
                      getAllSamples(currentPage);
                      setFetchAvailableSamples(true);
                      setShowAddMoreBtn(false);
                    }}
                    sx={{ textTransform: "none", mt: 3 }}
                  >
                    Add More Samples
                  </Button>
                )}
            </Box>
            {shipmentDetail?.shipment_status === "created" &&
              fetchAvailableSamples && (
                <>
                  <Box sx={{ mt: 4, mb: 1.5, fontFamily: "Open Sans" }}>
                    <Typography>
                      * Choose samples below to include in your package.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Typography
                        sx={{ fontFamily: "Open Sans", fontWeight: "600" }}
                      >
                        Select Multiple
                      </Typography>
                      <Switch
                        disabled={loading}
                        checked={checked}
                        onChange={(e) => handleToggleChange(e)}
                        inputProps={{ "aria-label": "controlled-switch" }}
                      />
                    </Box>
                    {shipmentDetail?.shipment_status === "created" &&
                      checked &&
                      selecteds?.length !== 0 && (
                        <Button
                          onClick={() => {
                            AddSamplesToShipment();
                          }}
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#1e5267",
                            ":hover": { background: "#1e5267" },
                            fontFamily: "Open Sans",
                            borderRadius: "8px",
                          }}
                        >
                          Add Selected
                        </Button>
                      )}
                  </Box>
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflowY: "auto",
                      border: "1px solid #e2e8f0",
                    }}
                  >
                    <TableContainer
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        mb: 2,
                      }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: "1px solid #e2e8f0",
                            background: "#fff",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e2e8f0",
                                borderBottom: "none",
                                display: { lg: "none" },
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Action
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e2e8f0",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Sample Name
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e2e8f0",
                                minWidth: "200px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Sample ID
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e2e8f0",
                                minWidth: "max-content",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Description
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #e2e8f0" }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Status
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e2e8f0",
                                borderBottom: "none",
                                display: { xs: "none", lg: "flex" },
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={"600"}
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Action
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {samplesList.length !== 0 &&
                            samplesList?.map((sample, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FFFFFF" : "#fff3eb",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e2e8f0",
                                    borderBottom: "none",
                                    display: { lg: "none" },
                                  }}
                                >
                                  <Box sx={{ display: "flex" }}>
                                    {checked ? (
                                      <Checkbox
                                        sx={{
                                          color: "#1e5267",
                                          "&.Mui-checked": {
                                            color: "#1e5267",
                                          },
                                        }}
                                        value={sample?.["Sample Id"]}
                                        checked={selecteds?.some(
                                          (item) =>
                                            item?.sample_id ===
                                            sample?.["Sample Id"],
                                        )}
                                        onChange={(e) => {
                                          handleCheckboxChange(e, sample);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          AddSampleToShipment(sample);
                                        }}
                                        disabled={loading}
                                        sx={{
                                          textTransform: "none",
                                          background: "#1e5267",
                                          ":hover": { background: "#1e5267" },
                                          color: "#fff",
                                          width: "80px",
                                          height: "35px",
                                        }}
                                      >
                                        {isAddingSample?.id ===
                                          sample?.["Sample Id"] &&
                                        isAddingSample?.status === true ? (
                                          <CircularProgress
                                            size={22}
                                            sx={{
                                              color: "#ff6412",
                                            }}
                                          />
                                        ) : (
                                          "Add"
                                        )}
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e2e8f0",
                                    borderBottom: "none",
                                    px: "16px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontFamily: "Open Sans" }}
                                    >
                                      {sample?.["Sample Name"]}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e2e8f0",
                                    borderBottom: "none",
                                    py: 0,
                                    px: "16px",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{ fontFamily: "Open Sans" }}
                                    >
                                      {sample?.["Sample Id"]}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e2e8f0",
                                    borderBottom: "none",
                                    py: 0,
                                    px: "16px",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        minWidth: "300px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      {sample?.["Description"]}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e2e8f0",
                                    borderBottom: "none",
                                    py: 2.5,
                                    px: "16px",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{ fontFamily: "Open Sans" }}
                                    >
                                      {sample?.["status"]}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    display: { xs: "none", lg: "flex" },
                                  }}
                                >
                                  <Box sx={{ display: "flex" }}>
                                    {checked ? (
                                      <Checkbox
                                        sx={{
                                          color: "#1e5267",
                                          "&.Mui-checked": {
                                            color: "#1e5267",
                                          },
                                        }}
                                        value={sample?.["Sample Id"]}
                                        checked={selecteds?.some(
                                          (item) =>
                                            item?.sample_id ===
                                            sample?.["Sample Id"],
                                        )}
                                        onChange={(e) => {
                                          handleCheckboxChange(e, sample);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          AddSampleToShipment(sample);
                                        }}
                                        disabled={loading}
                                        sx={{
                                          textTransform: "none",
                                          background: "#1e5267",
                                          ":hover": { background: "#1e5267" },
                                          color: "#fff",
                                          width: "80px",
                                          height: "35px",
                                        }}
                                      >
                                        {isAddingSample?.id ===
                                          sample?.["Sample Id"] &&
                                        isAddingSample?.status === true ? (
                                          <CircularProgress
                                            size={22}
                                            sx={{
                                              color: "#ff6412",
                                            }}
                                          />
                                        ) : (
                                          "Add"
                                        )}
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {page > 1 && (
                      <Box
                        sx={{
                          pb: 2,
                        }}
                      >
                        <Pagination
                          count={page}
                          page={currentPage}
                          onChange={(e, p) => {
                            setCurrentPage(p);
                          }}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShipmentDetail;
