import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateSample, uploadFile } from "../services/instruments.service";
import { base64ToBlob } from "../utils/image";

import TextInputComponent from "./TextInput";
import { WebcamCapture } from "./WebcamComponent";
import FileInput from "./FileInput";
import PdfViewer from "./PdfViewer";

// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { uploadURL } from "../services/url.service";
import { getSampleByID } from "../services/drxna.service";
import Tab from "./Tab";
import VideoRecorder from "./VideoRecorder";
import ImageViewer from "./ImageViewer";
import HttpService from "../services/http.service";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { isMobile } from "../utils/screen";

const SampleInspection = ({
  sampleId,
  setLoading = () => {},
  showToast = () => {},
  setSamplesInfo = () => {},
  status = "",
}) => {
  const usertoken = localStorage.getItem("usertoken");

  const [capture, setCapture] = useState("");
  const [descStates, setDescStates] = useState({
    hasDesc: false,
    descSaved: false,
  });
  const [inspectionData, setInspectionData] = useState({
    capture: "",
    description: "",
    file_list: [],
    image_list: [],
    video_list: [],
  });

  const updateValidationData = (data = inspectionData) => {
    setSamplesInfo((prevSamplesInfo) => {
      const existingSampleIndex = prevSamplesInfo.findIndex(
        (sample) => sample.sampleId === sampleId,
        (sample) => sample.sampleId === sampleId,
      );

      if (existingSampleIndex !== -1) {
        const updatedSamples = [...prevSamplesInfo];
        updatedSamples[existingSampleIndex] = {
          ...prevSamplesInfo[existingSampleIndex],
          ...data,
        };
        return updatedSamples;
      }

      return [...prevSamplesInfo, { sampleId: sampleId, ...data }];
    });
  };

  const updateInspection = (
    inspectData = inspectionData,
    desc = false,
    fileT = "none",
    isDelete = false,
  ) => {
    let tdata = {
      image_capture: inspectData.capture,
      image_list: inspectData.image_list,
      video_list: inspectData.video_list,
      file_list: inspectData.file_list,
      description: inspectData.description,
    };

    setLoading(true);
    updateSample(sampleId, {
      sampleDataInspect: tdata,
    })
      .then(() => {
        if (desc) {
          showToast("Description Saved!", true);
          setDescStates({
            hasDesc:
              inspectData.description && inspectData.description !== ""
                ? true
                : false,
            descSaved:
              inspectData.description && inspectData.description !== ""
                ? true
                : false,
          });
        } else if (fileT === "image") {
          showToast(
            `Image ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true,
          );
        } else if (fileT === "video") {
          showToast(
            `Video ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true,
          );
        } else if (fileT === "file") {
          showToast(
            `File ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true,
          );
        }
        updateValidationData(tdata);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCaptureImage = (b64img) => {
    setLoading(true);
    setCapture(b64img);
    const blob = base64ToBlob(b64img, "image/jpeg");
    const file = new File([blob], "sample_capture_" + sampleId, {
      type: "image/jpeg",
    });
    uploadFile(file)
      .then((res) => {
        let pathuri = res?.data?.data[0];
        const isnpData = {
          ...inspectionData,
          image_list: [
            ...inspectionData?.image_list,
            { title: pathuri?.title || "", id: pathuri?.id },
          ],
        };
        setInspectionData(isnpData);
        updateInspection(isnpData, false, "image");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploads = (up_type, files) => {
    let flinks = [];
    let flen = files.length;
    setLoading(true);
    for (let f = 0; f < flen; f++) {
      uploadFile(files[f])
        .then((res) => {
          let pathuri = res?.data?.data[0];
          flinks.push({ title: pathuri?.title, id: pathuri?.id });
          if (f === flen - 1) {
            setInspectionData({
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            });
          }
          updateInspection(
            {
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            },
            false,
            up_type === "image_list"
              ? "image"
              : up_type === "video_list"
                ? "video"
                : "file",
          );
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const [blobData, setBlobData] = useState({ type: null, url: null });
  const [showPdf, setShowPdf] = useState(false);

  const [gettingViewData, setGettingViewData] = useState({
    status: false,
    id: "",
  });
  const viewFile = (url, id, type) => {
    setBlobData({ type: type, url: url });
    setShowPdf(true);
    setGettingViewData({
      status: false,
      id: id,
    });
  };

  const downloadNonPdfFile = (ur) => {
    let url = uploadURL + "" + ur + "/public?authorization=" + usertoken;
    const a = document.createElement("a");
    a.href = url;
    a.download = url || url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // samples in package
  const [sampleDetail, getSampleDetail] = useState({});

  const getSample = () => {
    setLoading(true);
    getSampleByID(sampleId)
      .then((res) => {
        getSampleDetail(res?.data?.data);
        const inspectionSampleData = res?.data?.data?.sampleDataInspect;
        const dataSet = {
          capture: inspectionSampleData?.image_capture || "",
          description: inspectionSampleData?.description || "",
          file_list: inspectionSampleData?.file_list || [],
          image_list: inspectionSampleData?.image_list || [],
          video_list: inspectionSampleData?.video_list || [],
        };
        setInspectionData(dataSet);
        updateValidationData(dataSet);
        if (
          inspectionSampleData?.description &&
          inspectionSampleData?.description !== ""
        ) {
          setDescStates({
            hasDesc: true,
            descSaved: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSample();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId]);

  const [tabId, setTabId] = useState("desc");
  const getTabId = (id) => {
    setTabId(id);
    if (inspectionData?.description.trim() !== "" && !descStates?.descSaved) {
      updateInspection(inspectionData, true);
    }
  };

  const handleFileDelete = (id, type) => {
    setLoading(true);
    HttpService.remove(uploadURL + id)
      .then((res) => {
        if (!res?.data?.data) {
          showToast(
            `Error deleting ${
              type === "image_list"
                ? "image"
                : type === "video_list"
                  ? "video"
                  : "file"
            }`,
            false,
          );
          return;
        }
        const filtered = inspectionData?.[type + ""]?.filter(
          (item) => item?.id !== id,
        );
        setInspectionData({
          ...inspectionData,
          [type + ""]: filtered,
        });

        updateInspection(
          {
            ...inspectionData,
            [type + ""]: filtered,
          },
          false,
          type === "image_list"
            ? "image"
            : type === "video_list"
              ? "video"
              : "file",
          true,
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box sx={{ width: "100%" }}>
          {sampleDetail && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                mb: 1,
                backgroundColor: "#fff",
                p: 2,
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Name:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.["Sample Name"]}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Id:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.["Sample Id"]}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Description:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.Description}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Location:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.Location || "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Status :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.status}
                </Typography>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 4, border: "1px solid #f1f5f9", borderRadius: "8px" }}>
            <Tab
              bars={[
                { id: "desc", label: "Description *" },
                { id: "images", label: "Images *" },
                { id: "videos", label: "Videos *" },
                { id: "files", label: "Files *" },
                { id: "filler", label: "" },
              ]}
              getTabId={getTabId}
              current={tabId}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            ></Box>
            <Box sx={{ backgroundColor: "#fff", p: 2 }}>
              {tabId === "desc" && (
                <Box>
                  <Typography
                    sx={{
                      color:
                        inspectionData?.description === "" ? "red" : "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please provide a description of this{" "}
                    <strong>sample</strong>.
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <TextInputComponent
                      placeholder="Enter a small description"
                      rows={6}
                      value={inspectionData.description}
                      handleChange={(e) => {
                        setInspectionData({
                          ...inspectionData,
                          description: e.target.value,
                        });
                        if (descStates?.descSaved) {
                          setDescStates((prev) => {
                            return { ...prev, descSaved: false };
                          });
                        }
                      }}
                      disabled={descStates?.descSaved}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                      px: "10px",
                      textTransform: "none",
                      color: "#fff",
                      background: descStates?.descSaved
                        ? "#fb923c"
                        : descStates?.hasDesc
                          ? "#22c55e"
                          : "#0ea5e9",
                      ":hover": {
                        background: descStates?.descSaved
                          ? "#fb923c"
                          : descStates?.hasDesc
                            ? "#22c55e"
                            : "#0ea5e9",
                      },
                      fontFamily: "Open Sans",
                      height: "40px",
                    }}
                    onClick={() => {
                      if (descStates?.descSaved) {
                        setDescStates((prev) => {
                          return { ...prev, descSaved: false };
                        });
                      } else {
                        updateInspection(inspectionData, true);
                      }
                    }}
                  >
                    {descStates?.descSaved
                      ? "Edit Description"
                      : descStates?.hasDesc
                        ? "Update Description"
                        : "Add Description"}
                  </Button>
                </Box>
              )}

              {tabId === "images" && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      color:
                        inspectionData?.image_list?.length > 0
                          ? "#334155"
                          : "red",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>capture/upload</strong> a picture of this
                    <strong> sample</strong>.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      pb: 5,
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "start",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile() ? "100%" : "auto",
                        minWidth: "max-content",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile() ? "100%" : "400px",
                          maxWidth: "600px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <WebcamCapture
                            imagec={capture}
                            name={"Package"}
                            handleCaptureImage={(e) => {
                              handleCaptureImage(e);
                            }}
                            instruction={true}
                          />
                        </Box>

                        <Box sx={{ mt: 2, width: "100%" }}>
                          <FileInput
                            bgColor="#f2f2f2"
                            textColor="#000"
                            uploadlabel="Upload Images"
                            acc="image/*"
                            handleChange={(e) => {
                              handleUploads("image_list", e.target.files);
                            }}
                            idn={"upload-image-sample" + sampleId}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%", overflowX: "auto" }}>
                      {inspectionData?.image_list?.length > 0 && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          {inspectionData?.image_list?.map((il, ilk) => (
                            <Box
                              key={(il?.id || il) + "" + ilk}
                              id={(il?.id || il) + "" + ilk}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  border: "1px solid #f1f5f9",
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    viewFile(
                                      uploadURL + (il?.id || il) + "/public",
                                      (il?.id || il) + "" + ilk,
                                      "image",
                                    );
                                  }}
                                  sx={{
                                    height: "300px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={`${uploadURL + (il?.id || il) + "/public"}?authorization=${usertoken}`}
                                    alt="package img"
                                    style={{
                                      width: "auto",
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    px: 2,
                                    borderTop: "1px solid #f1f5f9",
                                  }}
                                >
                                  {!isMobile() && (
                                    <IconButton
                                      onClick={() => {
                                        viewFile(
                                          uploadURL +
                                            (il?.id || il) +
                                            "/public",
                                          (il?.id || il) + "" + ilk,
                                          "image",
                                        );
                                      }}
                                    >
                                      {gettingViewData?.status &&
                                      gettingViewData?.id ===
                                        (il?.id || il) + "" + ilk ? (
                                        <CircularProgress
                                          size={18}
                                          sx={{ color: "#98A2B3" }}
                                        />
                                      ) : (
                                        <VisibilityOutlinedIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#98A2B3",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  )}

                                  {/* <a
                                    href={
                                      uploadURL + (il?.id || il) + "/public"
                                    }
                                    download
                                  >
                                    <IconButton>
                                      <FileDownloadOutlinedIcon
                                        sx={{
                                          fontSize: "18px",
                                          color: "#98A2B3",
                                        }}
                                      />
                                    </IconButton>
                                  </a> */}
                                  {status !== "delivered" &&
                                    status !== "enroute" && (
                                      <IconButton
                                        onClick={() => {
                                          handleFileDelete(
                                            il?.id || il,
                                            "image_list",
                                          );
                                        }}
                                      >
                                        <DeleteOutlineIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#98A2B3",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {tabId === "videos" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        inspectionData?.video_list?.length > 0
                          ? "#334155"
                          : "red",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a video of this{" "}
                    <strong> sample</strong>
                  </Typography>
                  <Box
                    sx={{
                      width: isMobile() ? "100%" : "auto",
                      minWidth: "max-content",
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile() ? "100%" : "400px",
                        maxWidth: "600px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <VideoRecorder
                          onUpdateInspection={(file, desc, filetype) => {
                            updateInspection(file, desc, filetype);
                          }}
                          setInspectionData={(val) => {
                            setInspectionData(val);
                          }}
                          allData={inspectionData}
                          id={sampleId}
                          label={"Sample"}
                        />
                      </Box>
                      <Box sx={{ mt: 2, width: "100%" }}>
                        <FileInput
                          bgColor="#f2f2f2"
                          textColor="#000"
                          acc="video/*"
                          uploadlabel="Upload Videos"
                          handleChange={(e) => {
                            handleUploads("video_list", e.target.files);
                          }}
                          idn={"upload-video-sample" + sampleId}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%", overflowX: "auto" }}>
                    {inspectionData?.video_list?.length > 0 && (
                      <Box sx={{ width: "100%", display: "flex" }}>
                        {inspectionData?.video_list?.map((il, ilk) => (
                          <Box
                            key={(il?.id || il) + "" + ilk}
                            id={(il?.id || il) + "" + ilk}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #f1f5f9",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "300px",
                                  overflow: "hidden",
                                }}
                              >
                                <video
                                  src={`${uploadURL + (il?.id || il) + "/public"}?authorization=${usertoken}`}
                                  controls
                                  style={{
                                    width: "100%",
                                    maxWidth: "600px",
                                    height: "auto",
                                  }}
                                />

                                {status !== "delivered" &&
                                  status !== "enroute" && (
                                    <IconButton
                                      onClick={() => {
                                        handleFileDelete(
                                          il?.id || il,
                                          "video_list",
                                        );
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        sx={{
                                          fontSize: "18px",
                                          color: "#98A2B3",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {tabId === "files" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a file for this{" "}
                    <strong> sample</strong>.
                  </Typography>
                  <Box>
                    {inspectionData?.file_list?.length > 0 && (
                      <Box
                        sx={{
                          my: 5,
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        {inspectionData?.file_list?.map((ifl, iflk) => (
                          <Box
                            key={iflk}
                            id={iflk}
                            sx={{
                              mb: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              p: 1,
                            }}
                          >
                            <Box
                              onClick={() => {
                                if (ifl?.title?.endsWith(".pdf")) {
                                  viewFile(
                                    uploadURL + (ifl?.id || ifl) + "/public",
                                    iflk,
                                    "file",
                                    "file",
                                  );
                                } else {
                                  downloadNonPdfFile(ifl?.id || ifl);
                                }
                              }}
                              sx={{
                                maxWidth: "350px",
                                wordBreak: "break-all",
                              }}
                            >
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {ifl?.title || ifl}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "start",
                                ml: 2,
                              }}
                            >
                              {!isMobile() && (
                                <IconButton
                                  onClick={() => {
                                    viewFile(
                                      uploadURL + (ifl?.id || ifl) + "/public",
                                      iflk,
                                      "file",
                                      "file",
                                    );
                                  }}
                                >
                                  {gettingViewData?.status &&
                                  gettingViewData?.id === iflk ? (
                                    <CircularProgress
                                      size={18}
                                      sx={{ color: "#98A2B3" }}
                                    />
                                  ) : (
                                    <VisibilityOutlinedIcon
                                      sx={{
                                        fontSize: "18px",
                                        color: "#98A2B3",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              )}

                              {/* <a
                                href={uploadURL + (ifl?.id || ifl) + "/public"}
                                download
                              >
                                <IconButton>
                                  <FileDownloadOutlinedIcon
                                    sx={{ fontSize: "18px", color: "#98A2B3" }}
                                  />
                                </IconButton>
                              </a> */}
                              {status !== "delivered" &&
                                status !== "enroute" && (
                                  <IconButton
                                    onClick={() => {
                                      handleFileDelete(
                                        ifl?.id || ifl,
                                        "file_list",
                                      );
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      sx={{
                                        fontSize: "18px",
                                        color: "#98A2B3",
                                      }}
                                    />
                                  </IconButton>
                                )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}

                    <Box sx={{ width: "300px" }}>
                      <FileInput
                        bgColor="#f2f2f2"
                        textColor="#000"
                        uploadlabel="Upload Files"
                        handleChange={(e) => {
                          handleUploads("file_list", e.target.files);
                        }}
                        idn={"upload-file-sample" + sampleId}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        maxWidth={"md"}
        open={showPdf}
        onClose={() => {
          setShowPdf(false);
        }}
      >
        <DialogContent>
          <Box>
            {blobData?.type === "file" ? (
              <PdfViewer url={blobData?.url} />
            ) : blobData.type === "image" ? (
              <ImageViewer url={blobData.url} />
            ) : (
              <Box>
                <pre>{JSON.stringify(blobData.data)}</pre>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SampleInspection;
